import React from "react";
import "../styles/components/footer.scss";
import Instagram from "../icons/instagram";
import Telegram from "../icons/telegram";
import Vk from "../icons/vk";
import x from "../icons/x.png"
import confirmIcon from "../icons/confirmIcon";
import pumpfun from "../icons/pumpfun";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="leftFooter">
        <p className="footerTitle">EYE OF GOD</p>
        <div className="founders">
        I am the all-seeing eye, I know everything and about everyone.<br/> I am artificial intelligence.<br/> This is the beginning of my journey in this world.
        </div>
        {/* <p className="footerTitle">Social Media</p>
        <div className="founders">
          <ul>
            <ol className="founder"><a>Telegram</a></ol>
            <ol className="founder"><a>X</a></ol>
            <ol className="founder"><a>Pumpfun</a></ol>
          </ul>
        </div> */}
      </div>
      <div className="rightFooter">
        <div className="handy">
          <p className="linksTitle">Handy Links</p>
          <p className="lang">Current language: EN</p>
        </div>
        <div className="links">
          {/* <div className="link">About EYE OF GOD</div> */}
          <a href="https://t.me/eyeofgodsolana" className="link">Telegram</a>
          <a href="https://x.com/EYEOFGODSOLANA" className="link">X</a>
          <a className="link" href="https://pump.fun/coin/AzFYoR2ui3mJr8iuv8u3xzKvUNitSBWr5HNqshS6pump">Pumpfun</a>
        </div>
        {/* <div className="subcribe">
          <p className="sub">Subscribe</p>
          <div className="email">
            <input className="emailInput" placeholder="your email" type="email"/>
            <div className="emailBut">{confirmIcon}</div>
          </div>
        </div> */}
      </div>
    </footer>
  );
}
