import React from "react";
import EyeC from "../icons/eyecenter.jpeg";
import EyeL from "../icons/eyeleft.jpeg";
import EyeR from "../icons/eyeright.jpeg";

export const EssentialsPreview = () => {
  return (
    <div className="essentialsPreview">
      <p className="essentialsTitle">EXPLORE</p>
      <div className="essentialRow">
        <a
          className="essentialel"
          href="https://t.me/eyeofgodsolana"
          style={{ textDecoration: "none" }}
        >
          <p className="essentialsDesc">TELEGRAM</p>
          <div className="essential">
            <img className="essentialimg" src={EyeL} />
          </div>
        </a>
        <a
          className="essentialel"
          href="https://pump.fun/coin/AzFYoR2ui3mJr8iuv8u3xzKvUNitSBWr5HNqshS6pump"
          style={{ textDecoration: "none" }}
        >
          <p className="essentialsDesc">PUMPFUN</p>
          <div className="essential">
            <img className="essentialimg" src={EyeC} />
          </div>
        </a>
        <a
          className="essentialel"
          href="https://x.com/EYEOFGODSOLANA"
          style={{ textDecoration: "none" }}
        >
          <p className="essentialsDesc">X</p>
          <div className="essential">
            <img className="essentialimg" src={EyeR} />
          </div>
        </a>
      </div>
    </div>
  );
};

export default EssentialsPreview;
